import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Layout } from '../components/layout';
import styles from './recipe-template.module.scss';
import Image from 'gatsby-image';

interface Recipe {
  frontmatter: {
    description: string;
    title: string;
    featuredImage?: {
      childImageSharp?: {
        fluid: any;
      };
    };
  };
  html?: string;
}

interface RecipeTemplateProps {
  data: {
    markdownRemark: Recipe;
  };
}

export const RecipeTemplateComponent: React.FC<Recipe> = ({
  children,
  frontmatter: { title, description, featuredImage },
}) => {
  return (
    <div>
      <h1 className="text-4xl">{title}</h1>
      <p className="text-gray-500">{description}</p>
      {featuredImage?.childImageSharp ? (
        <Image className="my-4 max-w-full" fluid={featuredImage.childImageSharp.fluid} />
      ) : null}
      <div className={styles.recipeInstructions}>{children}</div>
    </div>
  );
};

const RecipeTemplate: React.FC<RecipeTemplateProps> = ({ data }) => (
  <Layout>
    <RecipeTemplateComponent {...data.markdownRemark}>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html! }} />
    </RecipeTemplateComponent>
  </Layout>
);

export const query = graphql`
  query Recipe($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`;

export default RecipeTemplate;
